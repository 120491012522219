import { TOGGLE_CK_SAVING } from '@/annotation/annotations.constants';

let globalInstance: Editor;
let presetHtml;

/**
 * Gets the data from CKEditor
 */
export function getHtmlReportEditor() {
  if (!globalInstance) {
    return;
  }
  return globalInstance.getData();
}

export function insertHtmlReportEditor(html: string) {
  //
}

/**
 * Replaces all the html with a new html string
 *
 * @param html - the html to set
 * @param forceUpdate - force CK to trigger an update on setting the HTML
 */
export function setHtmlReportEditor(html: string, forceUpdate?: boolean) {
  if (globalInstance) {
    // CRAB-25207: CK triggers an update event on `setData`, and we don't want fast followers to trigger updates
    // on receiving the latest document
    !forceUpdate && globalInstance.fire(TOGGLE_CK_SAVING, false);
    globalInstance.setData(html ?? '');
    !forceUpdate && globalInstance.fire(TOGGLE_CK_SAVING, true);
  } else {
    presetHtml = html;
  }
}

/**
 * Gets the editor scroll offset
 *
 * @returns the current scroll offset
 */
export function getScrollOffset() {
  const editorMain = document.querySelector('.ck-editor__main');
  return editorMain?.scrollTop ?? 0;
}

/**
 * Sets the editor scroll offset
 *
 * @param offset - a scroll offset
 */
export function setScrollOffset(offset: number) {
  // Setting the scroll offset right after a change to the document can sometimes cause the set to be eaten by
  // something CK does.
  setTimeout(() => {
    const editorMain = document.querySelector('.ck-editor__main');
    if (editorMain) {
      editorMain.scrollTop = offset;
    }
  }, 1);
}

/**
 * Sets the document html of the report when in view mode. In view mode the document is displayed in a simple
 * div. In edit mode, the document displays in the editor.
 *
 * @param {string} document - the html to set as the report view
 */
export function setReportViewHtml(document: string) {
  setHtmlReportEditor(document);
}

/**
 * Function to set the global instance of the editor that can be used across app
 */
export function setGlobalInstance(instance: any) {
  globalInstance = instance;
  return globalInstance;
}

/**
 * Function to set the global instance of the editor that can be used across app
 */
export function getGlobalInstance() {
  return globalInstance;
}

// CK screams and dies if you try to set the selection (cursor) to an invalid location. This checks the path of
// the position through the view and makes sure there is a valid element there.
export function isPositionValid(position: Position) {
  const path = position.path;
  if (!path.length) {
    return false;
  }

  let currentChild = getGlobalInstance().model.document.getRoot();
  if (!currentChild) return false;
  for (let i = 0; i < path.length; i++) {
    const pathValue = path[i];
    currentChild = currentChild.getChild(pathValue);
    if (currentChild === null && pathValue > 0 && i === path.length - 1) {
      return false;
    }
  }

  return true;
}

export function setCursorPosition(cursorPosition: any) {
  if (isPositionValid(cursorPosition)) {
    getGlobalInstance().model.change((writer) => {
      writer.setSelection(cursorPosition);
    });
  }
}

export function getCursorPosition() {
  return getGlobalInstance().model.document.selection.getFirstPosition();
}

export function focusReportEditor() {
  // Focus is called on switching to the journal view and depending on tick ordering the editor is sometimes not
  // initialized so this null check is needed
  getGlobalInstance()?.editing.view.focus();
}

export function executeCommand<T extends { command: string }>({ command, ...rest }: T): void {
  getGlobalInstance().execute(command, rest);
}
